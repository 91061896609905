import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Img from "gatsby-image";
import { theme } from "../styles";
import styled from '@emotion/styled'
import { Wrapper } from '../components'
import website from '../../config/website'

import {
  CatalogItem,
  CatalogHeader,
} from '../components'
import {
  CatalogWrapper
} from '../templates/catalogItemStyle'

const IndexWrapper = Wrapper.withComponent('main')
const Inner = styled.div`
  .gatsby-image-wrapper {
    max-width: 100% !important;
  }
  img {
    max-width: 100% !important;
    display: block;
  }
  p {
    margin: 1rem auto;
    font-family: "Lato" !important;
  }
  h2 {
    font-family: "Lato" !important;
    font-size: 2.4rem;
    margin: 0 0 0.5rem 0;
    font-weight: 500;
    color: #6d6d6d;
    text-transform: uppercase;
  }
  h3 {
    font-family: "Lato" !important;
    font-size: 1.4rem;
    margin: 0 0 2rem 0;
    font-weight: 300;
    color: #6d6d6d;
    text-transform: uppercase;
    span {
      font-weight: 500;
    }
    &.h2 {
      font-size: 1.8rem!important;
    }
  }
`;

const Head = styled.div`
  position: relative;
  margin-bottom: 1.35rem;
`;

const Hero = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  > div {
    width: 50%;
    &:first-of-type {
      padding-right: 1rem;
    }
    &:last-of-type {
      padding-left: 1rem;
    }
    p {
      margin-bottom: 1.35rem;
      font-size: 1.1rem;
      color: #6d6d6d;
    }
  }
  @media (max-width: ${theme.breakpoints.s}) {
    display: block;
    > div {
      width: 100%;
      &:first-of-type {
        padding-right: 0;
      }
      &:last-of-type {
        padding-left: 0;
      }
      p {
        &:first-of-type {
          padding-top: 1.35rem;
        }
      }
    }
  }
`;

const NovembreMMXXIII = ({ data }) => {

  const context = { slug: "exhibition", parent: "" };
  const artworks = data.artworks.nodes;
  
  return (
    <React.Fragment>
      <Helmet title={`Disch Rémy - La lettre`}>
        <link rel="stylesheet" type="text/css" href="https://unpkg.com/xzoom/dist/xzoom.css" media="all" />
        <script defer data-domain="dischr.com" src="https://plausible.io/js/script.js"></script>
      </Helmet>
      <CatalogHeader {...context} letter />
      <IndexWrapper id={website.skipNavId} style={{ paddingBottom: 0}}>
        <Inner>
          <Head>
            <Img
              fixed={data.banner.childImageSharp.fixed}
              placeholderStyle={{
                filter: "grayscale(100%) contrast(100%)",
                imageRendering: "pixelated",
                opacity: 0,
              }}
            />
            <Hero style={{ position: 'absolute', bottom: 0, left: '1rem' }}>
              <div style={{ width: '100%' }}>
                <h3 style={{ color: '#fff', opacity: .8 }}>
                  <small>Novembre 2023</small><br />
                </h3>
              </div>
            </Hero>
          </Head>
          <Hero>
            <div style={{ width: '100%', paddingTop: '2rem', paddingBottom: '2rem', paddingLeft: 0, paddingRight: 0 }}>
              <p>Bonjour,</p>

              <p>
                Beaucoup de jours sont passés depuis la dernière lettre.<br />
                Je suis en transit à vrai dire, entre deux chemins, à la recherche d'un lieu pour poser mes pinceaux.
              </p>

              <p>J’ai quitté la Bretagne en avril , je cherche à louer une maison isolée, idéalement dans le sud-ouest de la France, un atelier où je peux vivre et continuer à peindre et à plus long terme, créer un centre d'Arts, un lieu pour accueillir des gens qui désirent suivre des cours de peinture, de photographie, de danse, de musique, suivre des séances d'art thérapie ... Vous l'avez compris, toutes ces activités artistiques qui réveillent notre empathie, stimulent les émotions et restaurent la confiance en soi.</p>

              <p>
                Je pensais monter une exposition pour vous présenter les œuvres peintes en Bretagne, mais cela me demande toujours un investissement important et au vu de l’ambiance générale, je ne vois pas comment investir en étant sûr que le public réponde présent. Pour l’instant, je préfère patienter.<br />
                Pour ceux qui vivent à Paris, je me ferais un plaisir de vous présenter personnellement ces nouvelles toiles, elles sont disponibles chez David Braud.<br />
                Je vous rappelle que vous avez la possibilité de continuer à soutenir mon travail en consultant les nouvelles œuvres disponibles dans <a style={{ fontWeight: 600 }} className="regular-link" href={`https://www.dischr.com/catalog`} target="_blank">le catalogue 2023</a> et pour les petits budgets, vous avez la possibilité d'acquérir des œuvres originales (à partir de 100 €) dans <a style={{ fontWeight: 600 }} className="regular-link" href={`https://www.dischr.com/catalog/fonds-d-atelier`} target="_blank">le catalogue « Fonds d'Atelier »</a>.
              </p>

              <p>
                Comme vous le savez, la lettre est toujours liée à mon actualité. Et je dois dire qu’une fois encore je suis particulièrement fier de vous parler de cette nouvelle parution dans la revue <strong>Ogzylone</strong> de <strong>Valérie Mégardon</strong> , revue qui s’adresse aux professionnels et aux galeries d'art.<br />
                Son énorme coup de cœur pour mon travail lui a inspiré des textes qui racontent trois toiles qu'elle a choisies.
              </p>

              <p>
                Ses mots, qui accompagnent mes toiles, sont d’une justesse rare.<br />
                J’ai souvent été contacté par des auteurs (es), des éditeurs (es), qui m’ont proposé des ouvrages, des textes… Je n’avais jamais été convaincu.<br />
                La peinture avec des mots, c'est souvent compliqué.<br />
                Valérie Mégardon a réussi à toucher des zones très intimes et profondes qui coulent dans ma peinture et je vous l’avoue, j’ai été bousculé par son mouvement, sa pudeur, sa justesse.<br />
                Vous trouverez ces textes ci-dessous.
              </p>
              
              <p>Je reste à votre disposition si vous désirez des renseignements complémentaires, Je serais ravi de vous lire.</p>
              
              <p>
                Soutenez les artistes.<br />
                Merci,<br />
                Rémy
              </p>
              
            </div>
          </Hero>
        </Inner>
      </IndexWrapper>

      <IndexWrapper id={website.skipNavId} style={{ paddingBottom: 0 }}>
        <Inner>
          <Head>
            <Img
              fixed={data.sub_banner.childImageSharp.fixed}
              placeholderStyle={{
                filter: "grayscale(100%) contrast(100%)",
                imageRendering: "pixelated",
                opacity: 0,
              }}
            />
          </Head>
        </Inner>
      </IndexWrapper>
      
      <IndexWrapper id={website.skipNavId}>
        <Inner>
          <div style={{ marginTop: '2rem', marginBottom: '1rem' }}>
              <Hero>
                <a className="regular-link" href={data.og1.publicURL} target="_blank">
                  <img src={data.og1.childImageSharp.fixed.srcWebp} alt={`Revue Ogzylone - Valérie Mégardon`} />
                </a>
              </Hero>
          </div>

          <div style={{ marginBottom: '1rem' }}>
            <Hero>
              <a className="regular-link" href={data.og2.publicURL} target="_blank">
                <img src={data.og2.childImageSharp.fixed.srcWebp} alt={`Revue Ogzylone - Valérie Mégardon`} />
              </a>
            </Hero>
          </div>
        </Inner>
      </IndexWrapper>

      <IndexWrapper id={website.skipNavId} style={{ paddingBottom: 0 }}>
        <Inner>
          <Hero>
            <div style={{ width: '100%', paddingLeft: 0, paddingRight: 0 }}>
              <h3 style={{ marginTop: '5rem' }}>Oeuvres récentes</h3>
            </div>
          </Hero>
        </Inner>
      </IndexWrapper>

      {
        artworks.map((item, index) => {
          return (
            <CatalogWrapper 
              className="fullscreen description letter" 
              key={`page-artwork-${index}`}
            >
              <React.Fragment>
                <CatalogItem
                  key={`page-artwork-${index}`}
                  currentArtwork={{ document: [item] }}
                  count={1}
                  elementId={`artwork-${item.prismicId}`}
                  id={item.prismicId}
                  zoomPosition={'left'}
                  hideprice
                  hd
                  responsiveHeight
                />
                <p
                  style={{
                    marginTop: '2rem',
                    textAlign: 'center',
                    fontFamily: 'Roboto, sans-serif',
                    color: '#1c252b'
                  }}
                >
                  <strong>{item.data.title.text}</strong><br />
                  <span style={{
                    fontSize: '16px',
                    fontWeight: '100',
                    color: '#1c252b'
                  }}>{item.data.type}</span>
                </p>
              </React.Fragment>
            </CatalogWrapper>
          )
        })
      }

      <IndexWrapper id={website.skipNavId} style={{paddingTop: 0}}>
        <Inner>
          <Hero>
            <div style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column'
            }}>
              <a 
                href="https://www.dischr.com/catalog" 
                target="_blank"
                style={{
                  border: '1px solid #b4cfb0',
                  background: '#b4cfb0',
                  color: '#f2f2f2',
                  fontWeight: 'bold',
                  display: 'block',
                  padding: '.5rem 1rem',
                  borderRadius: '5px',
                  width: '16rem',
                  margin: '0 .5rem',
                  textAlign: 'center'
                }}
              >
                Consulter les prix du catalogue
              </a>

              <a 
                href="mailto:davidbraud@dischr.com"
                style={{
                  fontSize: '14px',
                  textDecoration: 'underline',
                  display: 'block',
                  marginTop: '.5rem'
                }}
              >
                contact et informations
              </a>
            </div>
          </Hero>
        </Inner>
      </IndexWrapper>

      <IndexWrapper id={website.skipNavId}>
        <Inner>
          <Hero>
            <div style={{ width: '100%', paddingLeft: 0, paddingRight: 0 }}>

              <h3 style={{ marginTop: '5rem' }}>Les catalogues</h3>
              <p>
                Vous pouvez accéder au <strong>catalogue des oeuvres 2023</strong> à cette adresse : <br />
                {` `}<a className="regular-link" href={`https://www.dischr.com/catalog`} target="_blank">https://www.dischr.com/catalog</a>{` `} <br /><br />
                et au <strong>catalogue fonds d'atelier</strong> à cette adresse : <br />
                {` `}<a className="regular-link" href={`https://www.dischr.com/catalog/fonds-d-atelier`} target="_blank">https://www.dischr.com/catalog/fonds-d-atelier</a>{` `}
              </p>
              
              <h3 style={{ marginTop: '5rem' }}>Acquérir des oeuvres</h3>
              <p>
                Si vous souhaitez acquérir et/ou vous informer de la disponibilité des oeuvres, 
                je vous invite à me contacter directement en utilisant l'adresse email et le numéro de téléphone en pied de page. 

              </p>
              <p>
                Vous pouvez également acquérir des oeuvres sur les site de notre partenaire <a className="regular-link" href="https://www.artsper.com/es/artistes-contemporains/france/102408/remy-disch" target="_blank">Artsper</a>
              </p>
              <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column'
              }}>
                <a 
                  href="https://www.icanvas.com/canvas-art-prints/artist/remy-disch" 
                  target="_blank"
                  style={{
                    border: '1px solid #b4cfb0',
                    background: '#b4cfb0',
                    color: '#f2f2f2',
                    fontWeight: 'bold',
                    display: 'block',
                    padding: '.5rem 1rem',
                    borderRadius: '5px'
                  }}
                >
                  Partenariat avec iCanvas
                </a>
              </div>
              
              <p>
                <span style={{ paddingTop: '3rem', display: 'block' }}>
                  ---
                  <br />(+33) 651 453 881
                  <br /><a className="regular-link" href="mailto: davidbraud@dischr.com">davidbraud@dischr.com</a>
                  <br /><a className="regular-link" href={`https://www.dischr.com`} target="_blank">dischr.com</a>
                </span>
              </p>
            </div>
          </Hero>
        </Inner>
      </IndexWrapper>
    </React.Fragment>
  );
};

export default NovembreMMXXIII;

export const pageQuery = graphql`
  query NovembreMMXXIII {
    banner: file(name: {eq: "Bandeau_lettre2"}) {
      childImageSharp {
        fixed(width: 1000, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sub_banner: file(name: {eq: "Bandeau_revue"}) {
      childImageSharp {
        fixed(width: 1000, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    og1: file(name: {eq: "revue-01-c"}) {
      publicURL
      childImageSharp {
        fixed(width: 800, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    og2: file(name: {eq: "revue-02-c"}) {
      publicURL
      childImageSharp {
        fixed(width: 800, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    artworks: allPrismicArtwork(
        filter: {
            prismicId: {
                in: [
                    "ZVeLNhIAACwAK99S", 
                    "ZUeTtRUAACwA13_k", 
                ]
            }
        }, 
        sort: {
            order: DESC, 
            fields: first_publication_date
        }) {
      nodes {
        prismicId
        data {
          meta_description
          meta_title
          title {
            text
          }
          drouot_estimate
          catalog_fullscreen
          type
          available
          on_demand
          price
          item {
            alt
            localFile {
              childImageSharp {
                fixed(height: 800, quality: 100) {
                  ...GatsbyImageSharpFixed_withWebp
                }
                fluid(maxWidth: 800, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          cadre {
            alt
            localFile {
              childImageSharp {
                fixed(height: 800, quality: 100) {
                  ...GatsbyImageSharpFixed_withWebp
                }
                fluid(maxWidth: 800, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          image_hd {
            localFile {
              childImageSharp {
                original {
                  height
                  src
                  width
                }
              }
            }
          }
          categories {
            ... on PrismicArtworkDataCategories {
              category {
                uid,
                document {
                  data {
                    name
                  }
                }
              }
            }
          }
          year {
            ... on PrismicArtworkDataYear {
              uid
            }
          }
        }
      }
    }
  }
`
